import { Box, Link, useMediaQuery, Drawer, SvgIcon } from "@material-ui/core";

import "./header.scss";
import Close from "../../assets/images/close.svg";
import Menu from "../../assets/images/menu.svg";
import Logo from "../../assets/images/logo.svg";
import LogoM from "../../assets/images/logo-m.svg";
import Twitter from "../../assets/images/twitter.svg";
import Discord from "../../assets/images//discord.svg";
import Github from "../../assets/images/github.svg";
import { ReactComponent as Medium } from "../../assets/images/medium.svg";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { success } from "src/slices/MessagesSlice";

export default function Header() {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch()


  const toastMsg = () => {
    dispatch(success("Live-net will be launched on December 10th, please use the test-net "))
  }

  return (
    <div className="header">
      <Box display="flex" alignItems="center" justifyContent="space-between" className="header-view">
        <Link component={NavLink} to="/" className="logo">
          <img src={isVerySmallScreen ? LogoM : Logo} />
        </Link>
        {isVerySmallScreen ? (
          <Box display="flex" alignItems="center" className="navList font-14">
            <img src={Menu} onClick={() => setIsOpen(true)} />
            <div className="navItem nav-trade font-family-DMSans">Trade</div>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" className="navList font-14">
            <div className="navItem navItemActive" >
              <a href="https://app.ede.finance/#" target={"_blank"} className="color1">Dashboard</a>
            </div>
            <div className="navItem" >
              <a href="https://app.ede.finance/#/Earn" target={"_blank"} className="color1">Earn</a>
            </div>
            <div className="navItem" >
              <a href="https://pancakeswap.finance/swap?chainId=56&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x4136129Ac3aC90cF9817548b24D35E73E9457e05" target={"_blank"} className="color1">Buy</a>
            </div>
            <div className="navItem" >
              <a href="https://app.ede.finance/#/ESBT" target={"_blank"} className="color1">ESBT</a>
            </div>
            <div className="navItem" >
              <a href="https://docs.ede.finance/" target={"_blank"} className="color1">Doc</a>
            </div>
            {/* <div className="navItem nav-trade font-family-DMSans">Trade</div> */}
            <a href="https://app.ede.finance/#/Trade" target={"_blank"} className="navItem nav-trade font-family-DMSans">
              <div className="">Trade</div>
            </a>
          </Box>
        )}
        <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
          <Box display="flex" flexDirection="column" alignItems="flex-end" className="drawer-view">
            <div className="close mt-30">
              <img src={Close} onClick={() => setIsOpen(false)} />
            </div>
            <Box display="flex" flexDirection="column" alignItems="flex-end" className="menu-list mt-20 font-20">
              <div className="menuItem" >
                <a href="https://app.ede.finance/#" target={"_blank"} className="color1">Dashboard</a>
              </div>
              <div className="menuItem" >
                <a href="https://app.ede.finance/#/Earn" target={"_blank"} className="color1">Earn</a>
              </div>
              <div className="menuItem" >
                <a href="https://pancakeswap.finance/swap?chainId=56&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x4136129Ac3aC90cF9817548b24D35E73E9457e05" target={"_blank"} className="color1">Buy</a>
              </div>
              <div className="menuItem" >
                <a href="https://app.ede.finance/#/ESBT" target={"_blank"} className="color1">ESBT</a>
              </div>
              <div className="menuItem" >
                <a href="https://docs.ede.finance/" target={"_blank"} className="color1">Doc</a>
              </div>
              {/* <div className="menuItem menuItemActive font-family-DMSans">Trade</div> */}
              <a href="https://app.ede.finance/#/Trade" target={"_blank"} className="menuItem menuItemActive font-family-DMSans">
                <div className="">Trade</div>
              </a>
            </Box>
            <Box display="flex" justifyContent="flex-end" alignItems="center" className="linkList">
              <a href="https://twitter.com/ede_finance" target="_blank">
                <img src={Twitter} />
              </a>
              <a href="https://discord.com/invite/g7GpVVxtxz" target="_blank">
                <img src={Discord} className="ml-24" />
              </a>
              <a href="https://medium.com/@ede_finance" target="_blank">
                <SvgIcon className="ml-24" component={Medium} color="#F89542" viewBox="0 0 24 24" />
              </a>
              <a href="" target="_blank">
                <img src={Github} className="ml-24" />
              </a>
            </Box>
          </Box>
        </Drawer>
      </Box>
    </div>
  );
}
