
import { Box, Dialog, useMediaQuery } from '@material-ui/core';

import Banner from "../../assets/images/banner.svg";
import Close from "../../assets/images/x.svg";
import Ido from "../../assets/images/ido.svg"
import './play.scss';

export default function PlayDialog(props) {
    const { show, setShow } = props;

    const isVerySmallScreen = useMediaQuery("(max-width: 680px)");

    const handleClose = () => {
        setShow(false);
    }

    const noLongerShow = () => {
        localStorage.setItem("noLongerShow", true)
        handleClose()
    }

    return (
        <div className='play'>
            <Dialog open={show}
                onClose={handleClose} >
                <div className="dialogBg">
                    <Box display="flex" justifyContent="center" alignItems="center" className="close pointer" onClick={handleClose}>
                        <img src={Close} className="closeIcon" />
                    </Box>
                    <img src={Banner} />
                    <Box display="flex" flexDirection="column" alignItems="center" className="box text-center">
                        <div className="color6 font-24 font-weight-b">- Special Event - </div>
                        <div className="mt-16 font-30 font-weight-b">a little World-Cup-2022 Game</div>
                        <div className="mt-14 font-14">To have some fun &highly anticipated 2022 Football World Cup,gain great and contribute the EDE IDO.</div>
                        <a href="https://wcg.ede.finance/" target="_blank" style={{ width: '100%' }}>
                            <Box display="flex" justifyContent="center" alignItems="center" className="playBtn mt-30 pointer color2 font-24 font-weight-b">PLAY NOW</Box>
                        </a>
                        {/* <Box display="flex" justifyContent="center" alignItems="center" className="closeBtn mt-16 pointer color6 font-16"
                            onClick={noLongerShow}>Close and no longer show</Box> */}
                        <Box display="flex" justifyContent="center" alignItems="center" className="closeBtn mt-16 pointer color6 font-16"
                            onClick={handleClose}>Close</Box>
                    </Box>
                    <a href="https://ido.ede.finance/" target="_blank" >
                        <img src={Ido} className="mt-16" />
                    </a>
                </div>
            </Dialog>
        </div>
    )
}