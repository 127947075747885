import { Box, Link, useMediaQuery } from "@material-ui/core";

import "./footer.scss";
import EldoradoBg from "../../assets/images/eldoradoBg.svg";
import Logo from "../../assets/images/logo2.svg";
import { NavLink } from "react-router-dom";

export default function Footer() {
  const isSmallScreen = useMediaQuery("(max-width: 1280px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 680px)");

  return (
    <div className="footer">
      <Box display="flex" flexDirection="column" alignItems="center" className="footer-view font-14 color10">
        <img src={Logo} className="" data-aos="zoom-in" />
        <Box display="grid" className="footList font-family-DMSans">
          <div className="footItem">
            <div className="color9">About</div>
            <div className="mt-24">
              <Link href="https://app.ede.finance/" target={"_blank"}>
                <span className=" color10">App</span>
              </Link>
            </div>
            <div className="mt-18">
              <Link component={NavLink} to="/Mission">
                <span className=" color10">Mission</span>
              </Link>
            </div>
            <div className="mt-18">
              <Link href="https://docs.ede.finance/bug-bounty" target={"_blank"}>
                <span className=" color10">Bug Bounty</span>
              </Link>
            </div>
            {/* <div className="mt-18">
                                <Link href="" target={'_blank'}><span className=" color10">Jobs</span></Link>
                            </div> */}
          </div>
          <div className="footItem">
            <div className="color9">Learn</div>
            <div className="mt-24">
              <Link href="https://docs.ede.finance/faqs" target={"_blank"}>
                <span className=" color10">FAQs</span>
              </Link>
            </div>
            <div className="mt-18">
              <Link href="https://docs.ede.finance/test-net/prepare" target={"_blank"}>
                <span className=" color10">Tutorials</span>
              </Link>
            </div>
            <div className="mt-18">
              <Link href="https://medium.com/@ede_finance" target={"_blank"}>
                <span className=" color10">Medium</span>
              </Link>
            </div>
            <div className="mt-18">
              <Link href="https://docs.ede.finance/trading/spot-and-leverage" target={"_blank"}>
                <span className=" color10">Trade</span>
              </Link>
            </div>
          </div>
          <div className="footItem">
            <div className="color9">Supports</div>
            <div className="mt-24">
              <Link href="https://docs.ede.finance/" target={"_blank"}>
                <span className=" color10">Documentation</span>
              </Link>
            </div>
            <div className="mt-18">
              <Link href="https://github.com/El-Dorado-Exchange" target={"_blank"}>
                <span className=" color10">Github</span>
              </Link>
            </div>
            <div className="mt-18">
              <Link href="https://www.dropbox.com/sh/q1uuvers6vxvpi0/AAA8A4XMNvKjTbQDKKdSEfRia?dl=0" target={"_blank"}>
                <span className=" color10">Media Kit</span>
              </Link>
            </div>
          </div>
          <div className="footItem">
            <div className="color9">Community</div>
            <div className="mt-24">
              <Link href="https://twitter.com/ede_finance" target={"_blank"}>
                <span className=" color10">Twitter</span>
              </Link>
            </div>
            <div className="mt-18">
              <Link href="https://discord.com/invite/g7GpVVxtxz" target={"_blank"}>
                <span className=" color10">Discord</span>
              </Link>
            </div>
            <div className="mt-18">
              <Link href="https://www.youtube.com/channel/UCRXCqsSP9jrooo_DT0HPCdA" target={"_blank"}>
                <span className=" color10">Youtube</span>
              </Link>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
}
